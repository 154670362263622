export default {
  apiRequestInProgress: false,
  user: {},
  events: [],
  previewEvent: [],
  customers: [],
  seatBlocks: [],
  customerCredits: [],
  customerTransactions: [],
  transactions: {},
  activity: [],
  reminders: []
};
