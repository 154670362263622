import React, { useEffect, useState } from 'react';

import { Card } from "@material-ui/core"
import { Title } from "@material-ui/icons"
import Logo from '../components/Logo';
import './NoInventoryErrorCard.scss'
import { CoyotesLogo } from '../components/CoyotesLogo'
export const NoInventoryErrorCard = () => {
    return (
        <Card className="main-card">
            <div className="logo-wrapper">
                <CoyotesLogo />
            </div>
            <div className="body-text">
                <h3>
                    Howdy!
                </h3>
                <p>
                    Credit redemptions through the portal are temporarily closed. Existing reservations will be available in your team account shortly. Please get in touch with your Coyotes Account Representative to redeem your FSA credits.
                </p>
                <p>
                    We will notify you when the portal opens again for redemption. See you then!
                </p>
                <p>
                    Phone: <a href="tel: (480) 563-7825">(480) 563-PUCK</a>
                </p>
                <p>
                    Email: <a  href="mailto:AccountManager@Arizonacoyotes.com">AccountManager@Arizonacoyotes.com</a>
                </p>
            </div>
        </Card>
    )
}