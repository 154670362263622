export default {
  THEME: 'primary',
  logo: require('../../images/logo.png'),
  seatMap: 'https://ss-stadiums.s3-us-west-1.amazonaws.com/coyotes-map.png',
  siteTitle: 'ARIZONA COYOTES',
  teamName: 'Arizona Coyotes',
  teamNameShort: 'Coyotes',
  SUBDOMAIN: 'coyotes',
  API_BASE_URL: 'https://coyotes-prod.herokuapp.com',
  ACCOUNT_MANAGER_LINK: 'https://am.ticketmaster.com/coyotes/#/',
  development: false,
  IMAGE_BASE_URL: 'https://s3.us-east-2.amazonaws.com/',
  BACKGROUND_IMAGE_NAME: 'coyotes-wallpaper.jpg',
  BACKGROUND_IMAGE_FILE: require('../../images/coyotes-wallpaper.jpg'),
  WHITE_LABEL_TEAM_ID: 116,
  banner: {
    choose: 'EVENT SELECTION',
    desc: 'Redeem your account credit for upcoming events. '
  },
  faqs: [
    {
      question: 'How does the Coyotes Credit Allocation Work?',
      answer:
        'The CCA allows fans to allocate game credits to specific events from the upcoming schedule. Simply log in to your Coyotes Team Account and select games from your available account credit.'
    },
    {
      question: 'Do I have to select all my games now?',
      answer:
        'No. You have the opportunity to allocate credit throughout the season. However, please note that prices are subject to change.'
    },
    {
      question: 'Where are event tickets delivered?',
      answer:
        'Event tickets are delivered directly within your Coyotes team account within 48 hours of selection.'
    },
    {
      question: 'Can I still forward and resell my tickets?',
      answer:
        'Yes. All account actions including forwarding and resale are available within your Coyotes team account after games are selected.'
    },
    {
      question:
        'Can credit be used to purchase BMW Lounge, AZBlue Club or Glass Seat tickets?',
      answer:
        'All membership levels may purchase BMW Lounge, AZBlue Club or Glass Seat tickets at the single-game rate (subject to availability).'
    },
    {
      question:
        "Can I exchange the tickets I've already purchased using the CCA system?",
      answer:
        'Yes, CCA tickets can be exchanged online via your Coyotes Team Account. '
    },
    {
      question: 'Can I purchase more than eight (8) tickets to a game?',
      answer:
        'No, you are unable to purchase more than eight tickets to a game using your account credit. Please contact your Coyotes Representative for more information on group ticket availability. '
    },
    {
      question: 'Can I add credit to my account once I run out?',
      answer:
        'Yes, you can add credit to your account at any time by calling your Coyotes Representative. '
    },
    {
      question:
        "If I don't use all of it, will my account balance roll over to next season?",
      answer:
        'Yes, any unused account balances will be rolled over to next season. '
    },
    {
      question:
        'Can I use my account balance to purchase Coyotes Suites or Group Event tickets?',
      answer:
        'No, your account balance cannot be used to purchase Coyotes Suites, Group Event tickets, or other special offer tickets. Please contact your Coyotes Representative to purchase tickets related to these programs.'
    },
    {
      question: 'Can my account balance be used for the postseason?',
      answer:
        'Your account balance cannot be used for the postseason. However, as a CCA member, you do qualify for exclusive postseason presale opportunities.'
    },
    {
      question: 'Can I get printed tickets?',
      answer:
        'No, the Coyotes Credit Account tickets are a digital-only ticketing option with tickets available for redemption directly within your Coyotes Team Account. '
    },
    {
      question: 'Prices are higher than expected. Why?',
      answer:
        'The price received for your Flexible Spending Account is the discounted rate per game, and varies from your full package rate if not committing to all home games. This is also the best rate & discount from the gate price.'
    }
  ]
};
