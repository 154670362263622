import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Axios from 'axios';
import { API_BASE_URL } from '../../data/environment';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  buttonStyle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '0.875rem',
    minWidth: '64px',
    paddingTop: '6px',
    paddingBottom: '6px',
    paddingRight: '15px',
    paddingLeft: '15px',
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: '4px',
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
}));

const Toolbar = ({ className, customerTransactions, ...rest }) => {
  const classes = useStyles();

  const [totalSalesAmount, setTotalSalesAmount] = React.useState(0)
  Axios.post(`${API_BASE_URL}/transactions/total`, null).then(res => {
    console.log(res.data.transactions[0].total)
    setTotalSalesAmount(res.data.transactions[0].total)
  })

  let transactionData = [];

  const transactionHeaders = [
    { label: 'Customer ID or Email', key: 'emailAddress' },
    { label: 'Account #', key: 'accountNumber' },
    { label: 'Reservation number', key: 'reserveOrderNumber' },
    { label: 'Sale Number', key: 'purchaseOrderNumber' },
    { label: 'Event Code', key: 'eventCode' },
    { label: 'Date', key: 'date' },
    { label: 'Time', key: 'time' },
    { label: 'Number of Seats', key: 'numSeat' },
    { label: 'Section/Row', key: 'section' },
    { label: 'First Seat/Last Seat', key: 'seatsNumber' },
    { label: 'Total Seat Cost', key: 'totalCost' },
    { label: 'Beginning Credit Balance', key: 'beginningCredit' },
    { label: 'Remaining Credit Balance', key: 'creditAvailable' }
  ];

  customerTransactions &&
    customerTransactions.map(transaction => {
      if (transaction.seatBlock.length > 0) {
        const eventCode = transaction.seatBlock[0].available_seat.split('-')[0];
        const section =
          transaction.seatBlock[0].available_seat.split('-')[1] +
          '/' +
          transaction.seatBlock[0].available_seat.split('-')[2];

        const seatsNumber =
          transaction.seatBlock[0].available_seat.split('-')[3] +
          ':' +
          transaction.seatBlock[
            transaction.seatBlock.length - 1
          ].available_seat.split('-')[3];

        transactionData.push({
          emailAddress: transaction.TeamAccount.emailAddress,
          accountNumber: transaction.TeamAccount.accountNumber,
          reserveOrderNumber: transaction.reserveOrderNumber,
          purchaseOrderNumber: transaction.purchaseOrderNumber,
          eventCode: eventCode,
          date: moment(transaction.dateCreated).format('MM-DD-YYYY'),
          time: moment(transaction.dateCreated).format('hh:mm A'),
          numSeat: transaction.numSeats,
          section: section,
          seatsNumber: seatsNumber,
          totalCost: transaction.totalCost,
          beginningCredit: transaction.totalCost,
          creditAvailable: transaction.TeamAccount.creditAvailable['$numberDecimal']
        });
      }
    });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" justifyContent="flex-end">
        <CSVLink
          className={classes.buttonStyle}
          filename={`Transactions-${moment().format('MM-DD-YYYY')}.csv`}
          data={transactionData}
          headers={transactionHeaders}
        >
          Export
        </CSVLink>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
            <h1>
              Total Sales: ${totalSalesAmount.toLocaleString()}
            </h1>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                placeholder="Search customer"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
