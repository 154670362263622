import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  makeStyles,
  TableSortLabel
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  hover: {
    cursor: 'pointer'
  }
}));

const Results = ({ className, customerTransactions, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleSelectAll = event => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customerTransactions.map(
        customer => customer._id
      );
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }
    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedCustomerIds.length === customerTransactions.length
                    }
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < customerTransactions.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Customer name</TableCell>
                <TableCell>Account #</TableCell>
                <TableCell>Number of seats</TableCell>
                <TableCell>Total cost</TableCell>
                <TableCell>Reservation number</TableCell>
                <TableCell>Section Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerTransactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(customer => (
                  <TableRow
                    hover
                    classes={{ hover: classes.hover }}
                    key={customer._id}
                    selected={selectedCustomerIds.indexOf(customer._id) !== -1}
                    onClick={() => {
                      console.log(customer);
                      history.push(
                        `/portal/transactions-details?id=${customer._id}`
                      );
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCustomerIds.indexOf(customer._id) !== -1
                        }
                        onChange={event => handleSelectOne(event, customer._id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>{customer.TeamAccount.emailAddress}</TableCell>
                    <TableCell>{customer.TeamAccount.accountNumber}</TableCell>
                    <TableCell>{customer.numSeats}</TableCell>
                    <TableCell>${customer.totalCost.toFixed(2)}</TableCell>
                    <TableCell>{customer.reserveOrderNumber}</TableCell>
                    <TableCell>{customer.sectionName}</TableCell>
                    <TableCell>
                      {moment(customer.dateCreated).format(
                        'MM/DD/YYYY hh:mm A'
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        color="primary"
                        label={
                          customer.status === 'RESERVED' ? 'RESERVED' : 'SOLD'
                        }
                        size="small"
                        style={{
                          backgroundColor:
                            customer.status === 'RESERVED' ? 'red' : 'green'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customerTransactions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customerTransactions: PropTypes.array.isRequired
};

export default Results;
