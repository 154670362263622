import * as types from './actionTypes';
import { customerApi } from '../../api/customerApi';
import {
  beginApiCall,
  apiCallError,
  endApiCall
} from '../apiStatus/apiStatusActions';
import { apiReponseError } from '../../api/config/apiRequestError';

export const loadAllCustomer = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getAllCustomer()
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_ALL_CUSTOMERS,
            payload: response.data.TeamAccounts
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadCustomerCredit = accountId => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getCustomerCredit(accountId)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_CUSTOMER_CREDITS,
            payload: response.data.teamAccount
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
        if(error.response.status == 501){ 
          setTimeout(()=>{
            window.location.href="/"
          },3000)
        }
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadCustomerHistory = accountId => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getCustomerHistory(accountId)
      .then(response => {
        if (response.data) {

          // sort by date
          response.data.transactions.transactions = response.data.transactions.transactions.sort((a,b)=> {
            return  new Date(b['dateCreated']) - new Date(a['dateCreated'])
          })

          dispatch({
            type: types.GET_CUSTOMER_HISTORY,
            payload: response.data.transactions
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadCustomerTransactions = () => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getCustomerTransactions()
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_CUSTOMER_TRANSACTIONS,
            payload: response.data.transactions
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadTransactions = customerId => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getTransactions(customerId)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.GET_TRANSACTIONS,
            payload: response.data.transactions
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const purchaseTicket = id => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .purchaseEventTicket(id)
      .then(response => {
        if (response.data) {
          dispatch({
            type: types.PURCHASED_TICKET,
            payload: response.data.transaction
          });
        }
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};

export const loadActivity = customerId => {
  return dispatch => {
    dispatch(beginApiCall());
    return customerApi
      .getActivity(customerId)
      .then(response => {
        dispatch({
          type: types.GET_ACTIVITY,
          payload: response.data.activity
        });
      })
      .catch(error => {
        dispatch(apiCallError());
        apiReponseError(error);
      })
      .finally(() => {
        dispatch(endApiCall());
      });
  };
};
