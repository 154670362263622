import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bluebadge from '../images/blue-badge.png';
import coyotesMap from '../images/coyotes-map.png';
import './css/product.scss';
import {
  siteTitle,
  banner,
  THEME,
  IMAGE_BASE_URL,
  API_BASE_URL,
  logo
} from './data/environment';
import Loader from '../components/Loader';
import swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import * as eventActions from '../state/event/eventAction';
import * as customerActions from '../state/customer/customerAction';
import Viewer from 'react-viewer';
import { ToastContainer } from 'react-toastify';
import { Button } from '@material-ui/core';
import { NavBar } from '../components/NavBar';

const Summary = () => {
  const events = useSelector(state =>
    state.events.events ? state.events.events : []
  );

  React.useEffect(() => {
  }, [events])

  const setBlocks = useSelector(state =>
    state.events.seatBlocks ? state.events.seatBlocks : []
  );

  const customerCredit = useSelector(state =>
    state.customers.customerCredits ? state.customers.customerCredits : []
  );

  const customerTransactionHistory = useSelector(state =>
    state.customers.customerCredits ? state.customers.customerHistory : []
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation()

  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [isSelectionSubmitted, setIsSelectionSubmitted] = useState(false);

  const LocationSelectorMenu = () => {
    return (
      <div className="center">
        <h2>Transactions</h2>
        <div className="location-table" style={{ marginBottom: "20px" }}>
          Please allow up to 2 hours to process orders. Barcodes can be accessed from your Coyotes Team Account.
        </div>
      </div>
    );
  };



  useEffect(() => {
    if(location.state && location.state['accountId']){
      const accountId = location.state['accountId']
      if (accountId) {
        dispatch(eventActions.loadAllEvents());
        dispatch(customerActions.loadCustomerCredit(accountId));
        dispatch(customerActions.loadCustomerTransactions(accountId))
        dispatch(customerActions.loadCustomerHistory(accountId))
        setAccountId(accountId)
      }
      window.mixpanel.track("Transaction History", {})
    } else {
      history.replace('/')
    }
  }, []);

  return (
    <div className={THEME}>
      {loading && <Loader />}
      <ToastContainer />
      <NavBar accountId={accountId}/>
      <div className="banner">
        <h1 className="banner-heading">CREDIT HISTORY</h1>
        <p className="banner-para">View past transactions</p>
      </div>
      <div className="main-cont">
        <LocationSelectorMenu />
        <div className="location-container">
          {customerTransactionHistory && customerTransactionHistory.transactions.map((trans, index) => (
            <div
              onClick={() => (console.log("hi"))}
              key={index}
              className="event-row flex-cont transX"
            >
              <div className="col-cont">
                <h1 className="col-head">
                  {trans.numSeats} {trans.numSeats == 1 ? 'Seat' : 'Seats'} in Section {trans.seatBlock[0].section_name}
                </h1>
                <p className="col-para ">
                  <span>
                    {
                      new Date(trans.dateCreated).toLocaleDateString()
                    }
                  </span>
                  <p>
                    Reservation #{trans.reserveOrderNumber}
                  </p>
                </p>
              </div>
              <div className="price-container">
                <p className="price">
                  ${trans.totalCost.toFixed(2)}
                </p>
              </div>
              {/* <div className="price-container">
                <p className="price">
                  Balance: <span>${trans.endingBalance.toFixed(2)}</span>
                </p>
              </div> */}
              <div className="price-container">
                <p className={`price ${trans.purchaseOrderNumber == 'NONE' ? 'pending' : 'sold'}`}>
                  {trans.purchaseOrderNumber == 'NONE' ? 'PENDING' : 'AVAILABLE'}
                </p>
              </div>
            </div>
          ))}
          {
            customerTransactionHistory && customerTransactionHistory.transactions.length == 0 &&
            <h3 className="no-seats">
              No transaction history available.
            </h3>
          }
        </div>
        <div className="small-card">
          <div className="btn-wrapper">
            {isSelectionSubmitted && (
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSelectionSubmitted(false);
                }}
                className="right-btn"
              >
                EDIT SELECTION
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="space-bottom"></div>
    </div>
  );
};

export default Summary;
