import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Button,
  DialogTitle,
  Divider,
  Typography,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { API_BASE_URL } from '../../data/environment';
import * as eventActions from '../../../state/event/eventAction';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {},
  inputLabel: {
    backgroundColor: 'white !important'
  }
}));

function EditEventModal({ handleDialog, event, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  //   useEffect(() => {
  //     console.log(event);
  //   }, [event]);

  const [loading, setLoading] = useState(false);
  const [eventCode, setEventCode] = useState(event.eventCode);
  const [description, setEventDescription] = useState(event.description);
  const [date, setEventDate] = useState(event.date);
  const [time, setEventTime] = useState(event.time);

  const handleChange = (event, type) => {
    let value = event.target.value;
    if (type === 'event-code') setEventCode(value);
    if (type === 'event-description') setEventDescription(value);
    if (type === 'event-date') setEventDate(value);
    if (type === 'event-time') setEventTime(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    updateEvent();
  };

  const updateEvent = async () => {
    setLoading(true);
    const endpoint = `${API_BASE_URL}/event/edit`;
    try {
      let response = await axios.post(endpoint, {
        eventId: event._id,
        description,
        date,
        time
      });

      if (response.status === 200) {
        await dispatch(eventActions.loadAllEvents());
        toast.success('Event updated successfully');
        handleDialog('close');
      }
    } catch (error) {
      toast.error('Error while updating event, please try again');
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
      className={classes.root}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" color="textPrimary">
          Edit Event
        </Typography>
      </DialogTitle>
      <Divider />

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box mb={2}>
            <FormControl variant="outlined" size="small" disabled>
              <InputLabel className={classes.inputLabel} htmlFor="event-code">
                Event code
              </InputLabel>
              <OutlinedInput
                id="event-code"
                value={eventCode}
                onChange={event => handleChange(event, 'event-code')}
                type="text"
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl variant="outlined" size="small">
              <InputLabel
                htmlFor="event-description"
                className={classes.inputLabel}
              >
                Event description
              </InputLabel>
              <OutlinedInput
                id="event-description"
                value={description}
                onChange={event => handleChange(event, 'event-description')}
                type="text"
              />
            </FormControl>
          </Box>
          <Box mb={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="event-date" className={classes.inputLabel}>
                Event Date
              </InputLabel>
              <OutlinedInput
                id="event-date"
                value={date}
                onChange={event => handleChange(event, 'event-date')}
                type="date"
              />
            </FormControl>
          </Box>

          <Box mb={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel htmlFor="event-time" className={classes.inputLabel}>
                Event Time
              </InputLabel>
              <OutlinedInput
                id="event-time"
                value={time}
                onChange={event => handleChange(event, 'event-time')}
                type="time"
              />
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={() => handleDialog('close')}>
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
            {loading && (
              <CircularProgress
                size={12}
                color="primary"
                style={{ marginLeft: 5 }}
              />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default EditEventModal;
