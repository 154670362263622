import coyotes from './env.coyotes';
import roadrunners from './env.roadrunners';

const Helper = arg =>
  process.env.REACT_APP_TYPE === 'coyotes' ? coyotes[arg] : roadrunners[arg];

export const THEME = Helper('THEME');
export const logo = Helper('logo');
export const siteTitle = Helper('siteTitle');
export const teamName = Helper('teamName');
export const seatMap = Helper('seatMap');
export const development = Helper('development');
export const teamNameShort = Helper('teamNameShort');
export const SUBDOMAIN = Helper('SUBDOMAIN');
export const ACCOUNT_MANAGER_LINK = Helper('ACCOUNT_MANAGER_LINK');
export const API_BASE_URL = Helper('API_BASE_URL');
export const IMAGE_BASE_URL = Helper('IMAGE_BASE_URL');
export const BACKGROUND_IMAGE_FILE = Helper('BACKGROUND_IMAGE_FILE');
export const BACKGROUND_IMAGE_NAME = Helper('BACKGROUND_IMAGE_NAME');
export const WHITE_LABEL_TEAM_ID = Helper('WHITE_LABEL_TEAM_ID');
export const banner = Helper('banner');
export const events = Helper('events');
export const totalPrice = Helper('totalPrice');
export const faqs = Helper('faqs');
