import React from 'react'
import '../views/css/loader.scss'
export default function Loader() {
  return (
    <div className='loaderCont'>
      <div className='loading-desc'>
      </div>
      <div className='lds-roller'>
      </div>
    </div>
  )
}
