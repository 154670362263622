import React from 'react';
import { useHistory } from 'react-router-dom';
import { development, logo, siteTitle } from '../views/data/environment';
import { Button } from '@material-ui/core';
import "./NavBar.scss"
import { AccessAlarm, List, MoneyOffRounded, MoneyOutlined, MoneyRounded, MoneySharp, MoneyTwoTone } from '@material-ui/icons';
import { DollarSign } from 'react-feather';
interface INavBarProps {
    accountId: string;
}

export const NavBar = (props: INavBarProps) => {
    const { accountId } = props;
    const history = useHistory()
    return (
        <div className="header-nav">
            <div
                onClick={() => {
                    history.push(`/`);
                }}
                className="logo-wrapper"
            >
                <img src={logo} className="logo2" alt="logo" />
                <h1 className="header-heading">
                    {siteTitle}
                    {
                        development && " DEV MODE"
                    }
                </h1>
            </div>
            <div className="navigation-links">
                <span onClick={() => {
                    history.push({
                        pathname: `/products`,
                        state: {
                          accountId: accountId
                        }
                      })
                }}>
                    {/* <DollarSign /> */}
                    Redeem Credit
                </span>
                <span onClick={() => {
                    history.push({
                        pathname: `/summary`,
                        state: {
                            accountId: accountId
                        }
                      })
                }}>
                    {/* <List /> */}
                    Transaction History
                </span>

            </div>
            <div className="profile-wrapper">
                <Button
                    color="primary"
                    fullWidth
                    onClick={() => {
                        history.replace('/thankyou')
                    }}
                    disabled={false}
                >
                    Log out
                </Button>
            </div>
        </div>
    )

}