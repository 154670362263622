import axios from 'axios';
import { API_BASE_URL } from '../../views/data/environment';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

export default apiClient;
