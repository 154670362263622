import React from 'react';
import yotesLogo from "../images/logo.png"
export const CoyotesLogo = () => {
  return (
    <img
      src={yotesLogo}
      className="coyotes-logo"
      style={{
        height: "100px"
      }}
    />
  );
};

export default CoyotesLogo;
