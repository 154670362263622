import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import * as customerActions from '../../../state/customer/customerAction';
import { API_BASE_URL, WHITE_LABEL_TEAM_ID } from '../../data/environment';
import Axios from 'axios';
import Swal from 'sweetalert2';
import * as eventActions from '../../../state/event/eventAction';

const useStyles = makeStyles(theme => ({
  reservedColor: { color: '#ff0000' },
  soldColor: { color: '#008000' },
  textMargin: {
    marginRight: 20
  },
  buttonPadding: {
    paddingTop: 21,
    paddingBottom: 19,
    paddingLeft: 20,
    paddingRight: 23,
    borderRadius: 10
  },
  costMargin: {
    marginTop: 22,
    color: '#000000',
    fontWeight: 300
  },
  customerMargin: {
    marginTop: 20
  },
  cardStyle: {
    overflow: "scroll",
    [theme.breakpoints.up('md')]: {
      display: 'block',
      height: '23vw'
    }
  },
  boxStyle: {
    marginTop: 'auto',
    marginRight: 0,
    marginBottom: 'auto',
    marginLeft: 0
  },
  contentStyle: {
    display: 'flex',
    height: '75%',
    flexDirection: 'column'
  },
  boxWidth: {
    width: '30%'
  },
  textStyle: {
    color: '#000000'
  },
  scrollView: {
    height: 350,
    overflow: 'auto'
  },
  customerCard: {
    paddingTop: 11,
    paddingBottom: 11
  }
}));

const Blocks = ({ transactions, event }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const customerTransactions =
    Object.keys(transactions).length > 0 ? transactions : null;

  const purchaseEventTicket = () => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get('id');
    let confirmYes = prompt('type yes');
    if (confirmYes == 'yes') {
      Axios.post(`${API_BASE_URL}/inventory/purchase/ticketmaster`, {
        teamId: WHITE_LABEL_TEAM_ID,
        transactionId: id
      })
        .then(response => {
          console.log('Purchase response: ', response);
          Swal.fire(
            'SUCCESS!',
            `<p>Successfully redeemed account credit! </p>`,
            'success'
          );
        })
        .catch(err => {
          Swal.fire(
            'Error!',
            `<p>${err.response.data.errorMessage} </p>`,
            'error'
          );
        });
    }
  };
  const previewCheckout = () => {
    Axios.post(`${API_BASE_URL}/inventory/checkout/ticketmaster`, {
      teamId: WHITE_LABEL_TEAM_ID,
      orderNumber: customerTransactions.reserveOrderNumber,
      accountId: customerTransactions.accountNumber
    }).then(response => {
      Swal.fire(
        'Checkout API',
        `<p>Account credit: $${response.data.accountCredit}</p><p>Total Due: $${response.data.totalDue}</p>`,
        'success'
      );
    });
  };

  const handleRelease = () => {
    alert('TODO');
  };

  return (
    <>
      <h1>Transaction Details</h1>
      {transactions && (
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Card className={classes.cardStyle}>
              <Box
                display="flex"
                p={3}
                className={classes.customerCard}
                alignItems="center"
              >
                <Typography variant="h5" color="textPrimary">
                  Customer Data
                </Typography>
                <Box flexGrow={1} />
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() =>
                    history.push(
                      `/portal/customer-details?account=${customerTransactions.TeamAccount.accountNumber}`
                    )
                  }
                >
                  See details
                </Button>
              </Box>

              <Divider />
              <CardContent>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Account #
                  </Box>
                  <Box>
                    <a href={'/customer-details'}>
                      {customerTransactions &&
                        customerTransactions.TeamAccount.accountNumber}
                    </a>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Email:
                  </Box>
                  <Box>
                    {customerTransactions &&
                      customerTransactions.TeamAccount.emailAddress}
                  </Box>
                </Box>
                {/* <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                > */}
                {/* <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Credit on Account:
                  </Box>
                  <Box>
                    $
                    {customerTransactions &&
                      customerTransactions.TeamAccount.creditAvailable['$numberDecimal']}
                  </Box> */}
                {/* </Box> */}
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Starting Balance:
                  </Box>
                  <Box>
                    $
                    {customerTransactions &&
                      customerTransactions.startingBalance}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Ending Balance:
                  </Box>
                  <Box>
                    $
                    {customerTransactions &&
                      Number(
                        customerTransactions.TeamAccount.creditAvailable
                          .$numberDecimal
                      ).toFixed(2)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Event Code:
                  </Box>
                  <Box>{event && event.event_name}</Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Event Description:
                  </Box>
                  <Box>{event && event.event_description}</Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Event Date:
                  </Box>
                  <Box>{event && event.event_date}</Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  className={classes.customerMargin}
                >
                  <Box className={clsx(classes.boxWidth, classes.textStyle)}>
                    Event Time:
                  </Box>
                  <Box>{event && event.event_time}</Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Card className={classes.cardStyle}>
              <CardHeader title="Seat Block" />
              <Divider />
              <CardContent className={classes.scrollView}>
                <Typography>
                  Total Cost: ${customerTransactions && customerTransactions.totalCost}
                </Typography>
                {customerTransactions ? (
                  <PerfectScrollbar>
                    {customerTransactions.seatBlock.map((seats, key) => (
                      <Card
                        key={key}
                        style={{
                          marginTop: 10,
                          backgroundColor: '#F7F7F7',
                          borderWidth: '1px',
                          borderColor: 'black',
                          borderStyle: 'solid'
                        }}
                      >
                        <CardContent
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                          }}
                        >
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography variant="h1">
                              {seats.available_seat.split('-')[1]}
                            </Typography>
                            <Typography variant="h5">Section</Typography>
                          </Box>
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography variant="h1">
                              {seats.available_seat.split('-')[2]}
                            </Typography>
                            <Typography variant="h5">Row</Typography>
                          </Box>
                          <Box
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                          >
                            <Typography variant="h1">
                              {seats.available_seat.split('-')[3]}
                            </Typography>
                            <Typography variant="h5">Seat</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </PerfectScrollbar>
                ) : (
                  <div></div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Card className={classes.cardStyle}>
              <CardHeader title="Sale Status" />
              <Divider />
              <CardContent className={classes.contentStyle}>
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="Column"
                  marginTop={6}
                  className={classes.boxStyle}
                >
                  <Typography
                    variant="h1"
                    className={
                      customerTransactions &&
                      customerTransactions.status === 'SOLD'
                        ? classes.soldColor
                        : classes.reservedColor
                    }
                  >
                    {customerTransactions &&
                    customerTransactions.status === 'SOLD'
                      ? 'SOLD'
                      : 'RESERVED'}
                  </Typography>
                  <Typography>
                    Order Number:
                    <b>
                      {customerTransactions &&
                      customerTransactions.status === 'SOLD'
                        ? customerTransactions &&
                          customerTransactions.purchaseOrderNumber
                        : customerTransactions &&
                          customerTransactions.reserveOrderNumber}
                    </b>
                  </Typography>
                  {customerTransactions &&
                  customerTransactions.status === 'RESERVED' ? (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      marginTop={3}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          purchaseEventTicket();
                        }}
                        disabled={loading}
                      >
                        {loading ? 'loading...' : 'SELL ONTO ACCOUNT'}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{
                          marginTop: '20px'
                        }}
                        onClick={() => {
                          previewCheckout();
                        }}
                        disabled={loading}
                      >
                        {loading ? 'loading...' : 'PREVIEW CHECKOUT'}
                      </Button>
                      <Button
                        color="secondary"
                        variant="contained"
                        style={{
                          marginTop: '20px'
                        }}
                        onClick={() => {
                          handleRelease();
                        }}
                        disabled={loading}
                      >
                        {loading ? 'loading...' : 'RELEASE & RECREDIT'}
                      </Button>
                    </Box>
                  ) : null}
                  <Typography className={classes.costMargin}>
                    {customerTransactions &&
                    customerTransactions.status === 'RESERVED'
                      ? `Total Cost: $${customerTransactions &&
                          customerTransactions.totalCost.toFixed(2)}`
                      : `Sold onto account on ${customerTransactions &&
                          moment(customerTransactions.soldDate).format(
                            'MM/DD/YYYY'
                          )}`}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default Blocks;
