import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bluebadge from '../images/blue-badge.png';
import coyotesMap from '../images/coyotes-map.png';
import './css/product.scss';
import {
  siteTitle,
  banner,
  THEME,
  IMAGE_BASE_URL,
  API_BASE_URL,
  logo,
  development,
  WHITE_LABEL_TEAM_ID,
  seatMap
} from './data/environment';
import Loader from '../components/Loader';
import swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';
import * as eventActions from '../state/event/eventAction';
import * as customerActions from '../state/customer/customerAction';
import Viewer from 'react-viewer';
import { ToastContainer } from 'react-toastify';
import { NoInventoryErrorCard } from './NoInventoryErrorCard';
import { Button } from '@material-ui/core';
import moment from 'moment';
import tConv24 from '../utils/utils';
import { NavBar } from '../components/NavBar';
import Axios from 'axios';
import { Bell as BellIcon } from 'react-feather';
import { Select, MenuItem } from '@material-ui/core';

const Products = () => {
  const events = useSelector(state =>
    state.events.events ? state.events.events : null
  );

  const seatBlocks = useSelector(state =>
    state.events.seatBlocks ? state.events.seatBlocks : []
  );

  const apiRequestInProgress = useSelector(state => {
    return state.apiRequestInProgress;
  });

  const customerCredit = useSelector(state =>
    state.customers.customerCredits ? state.customers.customerCredits : {}
  );

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [showMapImage, setShowMapImage] = useState(false);
  const [isSelectionSubmitted, setIsSelectionSubmitted] = useState(false);

  const [activeEventIndex, setActiveEventIndex] = useState(0);
  const [activeSeatCountIndex, setActiveSeatCountIndex] = useState(1);
  // Events in season

  const [creditBalance, setCreditBalance] = useState(0);
  const [accountId, setAccountId] = useState('');
  const [eventCodeDetail, setEventCodeDetail] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSection, setSelectedSection] = useState('all');
  const [seatState, setSeatState] = useState([]);

  const seatCountList = [
    { name: 'item1' },
    { name: 'item2' },
    { name: 'item3' },
    { name: 'item4' }
  ];

  useEffect(() => {
    // Getting unique section names
    let sections = [];
    seatBlocks.map(seat => {
      let section_name = seat.section_name;
      if (!sections.includes(section_name)) sections.push(section_name);
    });

    // Updating the state
    setSections(sections);
    setSeatState(seatBlocks);
  }, [seatBlocks]);

  useEffect(() => {
    if (selectedSection === 'all') {
      setSeatState(seatBlocks);
    } else {
      let sortedSeats = seatBlocks.sort();
    }
  }, [selectedSection]);

  const handleSortSeat = event => {
    let section = event.target.value;
    setSelectedSection(section);

    let sortedSeats = seatBlocks.filter(seat => seat.section_name === section);
    setSeatState(sortedSeats);
  };

  const handleSubscribeClick = async () => {
    window.mixpanel.track('Get notified clicked', {});
    const { value: phoneNumber } = await swal.fire({
      title: 'Get notified when seats are available for this matchup',
      input: 'number',
      inputLabel: 'Enter your phone number',
      inputValue: '',
      showCancelButton: false,
      customClass: {
        confirmButton: 'subscribe-confirm-btn',
        input: 'subs-phone-input'
      },
      confirmButtonText: 'Subscribe',
      inputValidator: value => {
        if (!value) {
          return 'Enter a valid phone number';
        }
      }
    });

    if (phoneNumber) {
      try {
        let endpoint = `${API_BASE_URL}/event/set_reminder`;
        const seatCount = activeSeatCountIndex + 1;
        const eventCode = events[activeEventIndex].eventCode;
        const teamId = WHITE_LABEL_TEAM_ID;

        let response = await Axios.post(endpoint, {
          phoneNumber,
          eventCode,
          seatCount,
          teamId
        });

        if (response.status === 200) {
          swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Well notify you when seats become available!'
          });
          window.mixpanel.track('Subscribed to reminders', {});
        }
      } catch (error) {
        swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong, please try again'
        });
      }

      // console.log(phoneNumber);
      // console.log(seatCount);
      // console.log(eventCode);
      // console.log(teamId);
    }
  };

  const handleEventClick = (index, eventCode) => () => {
    setActiveEventIndex(index);
    setEventCodeDetail(eventCode);
    const eventTicketData = {
      eventCode: eventCode,
      numSeats: activeSeatCountIndex + 1
    };
    dispatch(eventActions.loadSections(eventTicketData));
    window.mixpanel.track('Selected a game', {
      eventCode: eventCode
    });
  };

  const handleSeatCountClick = index => {
    setActiveSeatCountIndex(index);
    const eventTicketData = {
      eventCode: eventCodeDetail,
      numSeats: index + 1
    };
    dispatch(eventActions.loadSections(eventTicketData));
    dispatch(customerActions.loadCustomerCredit(accountId));
    window.mixpanel.track('Seat count click', {
      seatCount: eventTicketData.numSeats
    });
  };

  const SeatSelectorMenu = () => {
    return (
      <div className="team-scroll-container">
        <h2>How many seats?</h2>
        <h3
          onClick={() => {
            setShowMapImage(true);
          }}
          className="underline"
        >
          VIEW SEAT MAP
        </h3>
        <div className="selection-menu  seat-count-select">
          {seatCountList.map((el, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  handleSeatCountClick(i);
                }}
                className={`seat-count-item menu-item ${
                  i == activeSeatCountIndex ? 'active' : ''
                }`}
              >
                <span>{i + 1}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const LocationSelectorMenu = () => {
    return (
      <div className="center">
        <h2>Where do you want to sit?</h2>
        <div className="location-table"></div>
      </div>
    );
  };

  const SectionSortMenu = () => {
    return (
      <div className="center section-selector">
        <Select
          value={selectedSection}
          className="section-select-input"
          onChange={handleSortSeat}
          variant="outlined"
          id="seat-section-select"
          MenuProps={{ id: 'section-select-items' }}
        >
          <MenuItem value="all">All sections</MenuItem>
          {sections.map((section, index) => (
            <MenuItem value={section} key={index}>
              Section {section}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  const handleLocationClick = (sectionName, price, rowNum) => {
    window.mixpanel.track('Seen confirm prompt', {});
    const date = moment(events[activeEventIndex]['date']).format(
      'dddd MMMM DD, YYYY'
    );
    const time = tConv24(events[activeEventIndex]['time']);
    swal
      .fire({
        title: '<strong>Confirm Date and Seats</strong>',
        icon: 'info',
        html:
          `
          <span>Date: <b>${date} ${time}</b></span></br></br>
          <b>${activeSeatCountIndex + 1} ${
            activeSeatCountIndex == 0 ? 'Seat' : 'Seats'
          } </b>in <b> Section ${sectionName}, Row ${rowNum} </b></br>` +
          `Total Cost <b>$${((activeSeatCountIndex + 1) * price).toFixed(
            2
          )}</b></br>` +
          `Credit balance after purchase: <b>$${parseFloat(
            customerCredit.creditAvailable['$numberDecimal'] -
              (activeSeatCountIndex + 1) * price
          ).toFixed(2)}</b></br>`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Confirm',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText: 'Cancel',
        cancelButtonAriaLabel: 'Thumbs down',
        allowOutsideClick: false
      })
      .then(async result => {
        if (result.isConfirmed) {
          setLoading(true);
          const confirmSeatData = {
            numSeats: activeSeatCountIndex + 1,
            eventCode: eventCodeDetail,
            sectionName: sectionName,
            accountId: accountId,
            rowNum: rowNum
          };
          const ticketBook = await eventActions.confirmTicket(confirmSeatData);
          setLoading(false);
          if (ticketBook) {
            window.mixpanel.track('Purchased seats', {
              totalCost: `${((activeSeatCountIndex + 1) * price).toFixed(2)}`
            });
            swal
              .fire({
                title: '<strong>Success!</strong>',
                icon: 'success',
                html: `Your order for <b>${activeSeatCountIndex + 1} ${
                  activeSeatCountIndex == 0 ? 'Seat' : 'Seats'
                }</b> in <b>Section ${
                  ticketBook.seatBlock[0].section_name
                }</b> has been placed. A receipt has been sent to your email. Your tickets will be available in your Team Account shortly.`,
                confirmButtonText: 'Done',
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: 'Choose more events'
              })
              .then(res => {
                dispatch(customerActions.loadCustomerCredit(accountId));
                // update inventory
                const eventTicketData = {
                  eventCode: eventCodeDetail,
                  numSeats: activeSeatCountIndex + 1
                };
                dispatch(eventActions.loadSections(eventTicketData));
                if (res.dismiss == 'cancel') {
                } else {
                  history.push('/thankyou');
                }
              });
          }
        }
      });
  };

  useEffect(() => {
    if (location.state && location.state['accountId']) {
      const accountId = location.state['accountId'];
      if (accountId) {
        dispatch(eventActions.loadAllEvents());
        setAccountId(accountId);
        dispatch(customerActions.loadCustomerCredit(accountId));
      }
      Axios.post(`${API_BASE_URL}/configuration/enabled`).then(res => {
        setIsEnabled(res.data.isPortalOpen);

        if(development)
          setIsEnabled(true)
      });
    } else {
      history.replace('/');
    }
  }, [location]);

  useEffect(() => {
    if (customerCredit.length > 0) {
      window.mixpanel.identify(`${accountId}`);
      window.mixpanel.people.set({
        $email: customerCredit[0]['emailAddress'],
        credits: customerCredit[0].creditAvailable['$numberDecimal']
      });
      window.mixpanel.track('Viewing Inventory', {});
      window.Intercom('boot', {
        user_id: accountId
      });
    }
  }, [customerCredit]);

  useEffect(() => {
    if (!events.length) return;
    setEventCodeDetail(events[activeEventIndex].eventCode);
    const eventTicketData = {
      eventCode: events[activeEventIndex].eventCode,
      numSeats: activeSeatCountIndex + 1
    };
    setLoading(true);
    dispatch(eventActions.loadSections(eventTicketData));
  }, [events]);

  useEffect(() => {
    setLoading(false);
  }, [events]);

  const selected = 'item1';

  const getSeatNumString = (availableSeat, numSeats) => {
    let explode = availableSeat.split('-');
    let ret = 'Seats: ';
    for (let i = 0; i < numSeats; i++) {
      if (i == numSeats - 1) ret = ret + (parseInt(explode[3]) + i);
      else ret = ret + (parseInt(explode[3]) + i) + ', ';
    }
    return ret;
  };

  return (
    <div className={THEME}>
      {loading && <Loader />}
      <Viewer
        visible={showMapImage}
        onClose={() => {
          setShowMapImage(false);
        }}
        images={[{ src: seatMap, alt: '' }]}
      />
      <ToastContainer />
      <NavBar accountId={accountId} />
      <div className="banner">
        <h1 className="banner-heading">{banner.choose}</h1>
        <p className="banner-para">{banner.desc}</p>
      </div>
      <div className="main-cont">
        {events.length > 0 && isEnabled == true && (
          <>
            <div className="team-scroll-container">
              <h2>What game do you want to attend?</h2>
              <h4>Scroll to see more events</h4>
              <div
                id="main-container"
                className="selection-menu"
                enableResetScrollToCoords={false}
              >
                {events.map((event, i) => {
                  return (
                    <div
                      key={i}
                      onClick={handleEventClick(i, event.eventCode)}
                      className={`team-menu-item menu-item ${
                        i == activeEventIndex ? 'active' : ''
                      }`}
                    >
                      <div className="logo team-logo">
                        <img
                          src={event['logoUrl']}
                          className="logo team-logo"
                          alt="logo"
                        />
                      </div>
                      <div className="event">
                        <span>{event.description}</span>
                        <span>
                          {/* Tuesday 10/15/2020 */}
                          {moment(event.date).format('dddd MMMM DD, YYYY')}
                        </span>
                        <span>{tConv24(event.time)}</span>
                        {event.availableSeatCount == 0 && (
                          <span style={{ color: 'red' }}>SOLD OUT</span>
                        )}
                        {event.availableSeatCount > 0 && (
                          <span style={{ color: 'green' }}>
                            {event.availableSeatCount} Seats
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <SeatSelectorMenu />
            <LocationSelectorMenu />
            <SectionSortMenu />
            <div className="location-container">
              {seatState.map((seat, index) => (
                <div
                  onClick={() =>
                    handleLocationClick(
                      seat.section_name,
                      seat.price,
                      seat.row_num
                    )
                  }
                  key={index}
                  className="event-row flex-cont"
                >
                  <div className="col-cont">
                    <h2 className="col-para section">
                      Section {seat.section_name}
                    </h2>
                    <h2 className="col-para section">Row {seat.row_num} </h2>
                    <p className="col-para seatDetails">
                      <span>
                        {getSeatNumString(seat.available_seat, seat.num_seats)}
                      </span>
                    </p>
                    <p className="col-para seatDetails">
                      <span>
                        ${parseFloat(seat.price).toFixed(2)} each
                      </span>
                    </p>
                  </div>
                  <div className="price-container">
                    <p className="price">
                      ${(seat.price * (activeSeatCountIndex + 1)).toFixed(2)}{' '} Total
                    </p>
                  </div>
                  <div className="checkbox-container">
                    <button className="btn btn-main">SELECT</button>
                  </div>
                </div>
              ))}
              {seatBlocks.length == 0 && apiRequestInProgress == false && (
                <>
                  <h3 className="no-seats">
                    {activeSeatCountIndex == 0
                      ? 'Sorry, 1 seat purchases are currently unavailable.'
                      : 'No seats available. Please check your selection and try again.'}
                  </h3>
                  <div className="subs-btn-wrap">
                    <button
                      className="btn-subscribe"
                      onClick={handleSubscribeClick}
                    >
                      <BellIcon />
                      <span>GET NOTIFIED</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {isEnabled == false && <NoInventoryErrorCard />}
        <div className="small-card">
          <div>
            {isSelectionSubmitted && <h1 className="submitted">Submitted!</h1>}
            {!isSelectionSubmitted && (
              <h1 className="display-column creditAvailable">
                <span>Credit Available: </span>$
                {customerCredit['creditAvailable']
                  ? Number(
                      customerCredit.creditAvailable['$numberDecimal']
                    ).toLocaleString()
                  : 0}
              </h1>
            )}
          </div>
          <div className="btn-wrapper">
            {isSelectionSubmitted && (
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsSelectionSubmitted(false);
                }}
                className="right-btn"
              >
                EDIT SELECTION
              </button>
            )}
            {/* {!isSelectionSubmitted && (
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setLoading(true)
                  setTimeout(() => {
                    setLoading(false)
                    setIsSelectionSubmitted(true)
                    swal({
                      title: 'Success!',
                      text:
                        'Submitted your ticket selection. Tickets will be made available in your Team Account within 48 hours. Contact Live Support for any questions. ',
                      icon: 'success'
                    })
                  }, 2000)
                }}
                className='right-btn'
              >
                DONE CHOOSING GAMES
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="whole-footer">
        <div className="footer">
          <div className="badge-cont">
            <img src={bluebadge} className="footer-badge" alt="logooovhvkj1" />
            <p className="foot-texts">Built for fans</p>
          </div>
          <div className="links-cont">
            <b>
              <p className="foot-texts">Privacy Policy</p>
            </b>
            <b>
              <p className="foot-texts">About us</p>
            </b>
            <b>
              <p className="foot-texts">Terms of services</p>
            </b>

            <b>
              <p className="foot-texts">Press</p>
            </b>
          </div>
        </div>
        <div className="copyright-cont">
          <p className="bottom-texts">Copyright 2020 Season Share Inc.</p>
        </div>
      </div>
      <div className="space-bottom"></div>
    </div>
  );
};

export default Products;
