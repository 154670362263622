import React, { useState } from 'react'
import one from '../images/1.png'
import two from '../images/2.png'
import three from '../images/3.png'

import teamAuth from '../images/team-auth.png'
import selectGames from '../images/choose-games.png'
import getTickets from '../images/get-tickets.png'

import banner from '../images/banner.png'
import bluebadge from '../images/blue-badge.png'
import Accordion from '../components/Accordion'
import { siteTitle, THEME, faqs, logo, SUBDOMAIN, WHITE_LABEL_TEAM_ID, API_BASE_URL, teamName, BACKGROUND_IMAGE_FILE as heroImage } from '../views/data/environment'
import { useHistory } from 'react-router-dom'

import axios from 'axios';

import './css/home.scss'
import Swal from 'sweetalert2'
export default function ThankYou() {
  const history = useHistory()

  const [teamAccountUrl, setTeamAccountUrl] = useState('');

  React.useEffect(() => {
  }, [])

  return (
    <div>
      <div id={THEME} style={{ backgroundImage: `url(${heroImage})`, height: "100vh" }} >
        <div className='text-col'>
          <h1 className='header-text'>THANK YOU! </h1>
          <p className='banner-paras'>We're excited to have you with us this season.</p>
          <button className="btn main-btn" onClick={() => {
                history.push('/')
          }}>
              HOME
            </button>
        </div>
      </div>
    </div>
  )
}
