import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
// import GlobalStyles from 'src/components/GlobalStyles';
// import 'src/mixins/chartjs';
import theme from './theme';
import Routes from './routes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './views/Home';
import './App.scss';

import Products from './views/Products';
import CustomerListView from './views/customer/CustomerListView';
import DashboardView from './views/reports/DashboardView';
import EventConfig from './views/events/EventConfig';
import TransactionsListView from './views/transactions/TransactionsListView';
import TransactionsDetails from './views/transactions/TransactionsDetails';
import Login from './views/auth/LoginView';
import PrivateRoute from './routes/privateRoutes';
import PublicRoute from './routes/publicRoutes';
import ThankYou from './views/ThankYou';
import Summary from './views/Summary';
import { Helmet } from 'react-helmet';
import { siteTitle } from './views/data/environment';
import AddCreditView from './views/AddCreditView';
import CustomerDetails from './views/customer/CustomerDetails';
import EventReminders from './views/EventReminders';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf('portal') > 0) {
      toggleIntercom('none');
    } else {
      toggleIntercom('block');
    }
  });

  const toggleIntercom = mode => {
    let checkElement = setInterval(function() {
      let intercom = document.getElementsByClassName('intercom-launcher');
      if (intercom.length) {
        intercom[0].style.display = mode;
        clearInterval(checkElement);
      }
    }, 100);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Helmet>
          <html lang="en" />
          <title>{siteTitle}</title>
          <meta name="description" content={siteTitle} />
        </Helmet>
        <Switch>
          <PublicRoute component={Home} path="/" exact></PublicRoute>
          <PublicRoute component={Login} path="/login" exact></PublicRoute>
          <PublicRoute
            component={Products}
            path="/products"
            exact
          ></PublicRoute>
          <PublicRoute
            component={Home}
            path="/tmcallback/linkAccount"
            exact
          ></PublicRoute>
          <PublicRoute
            component={ThankYou}
            path="/thankyou"
            exact
          ></PublicRoute>
          <PublicRoute component={Summary} path="/summary" exact></PublicRoute>
          <PrivateRoute
            component={CustomerListView}
            path="/portal/customers"
          ></PrivateRoute>
          <PrivateRoute
            component={CustomerDetails}
            path="/portal/customer-details"
          ></PrivateRoute>
          <PrivateRoute
            component={AddCreditView}
            path="/portal/addcredit"
          ></PrivateRoute>
          <PrivateRoute
            component={CustomerListView}
            path="/admin"
          ></PrivateRoute>
          <PrivateRoute
            component={EventConfig}
            path="/portal/events"
          ></PrivateRoute>
          <PrivateRoute
            component={TransactionsListView}
            path="/portal/transactions"
          ></PrivateRoute>
          <PrivateRoute
            component={TransactionsDetails}
            path="/portal/transactions-details"
          ></PrivateRoute>
          <PrivateRoute
            component={EventReminders}
            path="/portal/eventreminders"
          ></PrivateRoute>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
