import apiClient from './config/apiClient';
import { WHITE_LABEL_TEAM_ID } from '../views/data/environment';
import { eventEndPoint, eventSeatEndPoint } from './config/apiEndpoints';
import { deleteEvent } from '../state/event/eventAction';

export const eventApi = {
  getAllEvents() {
    return apiClient.get(eventEndPoint + 's').then(response => {
      return response;
    });
  },
  previewEvent(eventCode) {
    const eventData = {
      teamId: WHITE_LABEL_TEAM_ID,
      eventCode: eventCode
    };
    return apiClient
      .post(eventEndPoint + '/preview', eventData)
      .then(response => {
        return response;
      });
  },
  saveEvent(eventCode, logoUrl) {
    const eventData = {
      teamId: WHITE_LABEL_TEAM_ID,
      eventCode: eventCode,
      logoUrl: logoUrl
    };
    return apiClient
      .post(eventEndPoint + '/preview/save', eventData)
      .then(response => {
        return response;
      });
  },
  getSections(eventTicketData) {
    const eventTicket = {
      numSeats: eventTicketData.numSeats,
      eventCode: eventTicketData.eventCode,
      teamId: WHITE_LABEL_TEAM_ID
    };
    return apiClient
      .post(eventSeatEndPoint + '/sections', eventTicket)
      .then(response => {
        return response;
      });
  },
  bookTicket(confirmTicket) {
    const confirmTicketData = {
      numSeats: confirmTicket.numSeats,
      eventCode: confirmTicket.eventCode,
      sectionName: confirmTicket.sectionName,
      teamId: WHITE_LABEL_TEAM_ID,
      accountId: confirmTicket.accountId,
      rowNum: confirmTicket.rowNum
    };
    return apiClient
      .post(eventSeatEndPoint + '/checkout', confirmTicketData)
      .then(response => {
        return response;
      });
  },
  deleteEvent(eventId) {
    const eventData = {
      eventId: eventId
    };
    return apiClient
      .post(eventEndPoint + '/delete', eventData)
      .then(response => {
        return response;
      });
  }
};
