import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/DashboardLayout';
import {
  Box,
  Container,
  makeStyles,
  Paper,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
  Divider,
  Grid
} from '@material-ui/core';
import Page from '../../components/Page';
import Axios from 'axios';
import { API_BASE_URL, WHITE_LABEL_TEAM_ID } from '../data/environment';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddCreditView = () => {
  const classes = useStyles();
  const [accountNumber, setAccountNumber] = useState('16934269');
  const [creditAbout, setCreditAmount] = useState('');

  const handleInputChange = (event, type) => {
    let value = event.target.value;
    if (type === 'account-number') setAccountNumber(value);
    if (type === 'credit-about') setCreditAmount(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  const handleLookup = () => {
    const data = {
      "teamId": WHITE_LABEL_TEAM_ID,
      "accountId": accountNumber
    }
    const API_BASE_URL = "http://localhost:3001"
    Axios.post(`${API_BASE_URL}/credit/lookup`, data).then(res => {
      console.log(res.data)
      Swal.fire("Success!", `Initial balance $${res.data.initialBalance} </br> </br> Current Balance $${res.data.currentBalance}`, "success")
    }, err=> {
      Swal.fire("Foul!", err.response.data.errorMessage, "error")
    })
  }

  const handleAddCredit = () => {
    const data = {
      "teamId": WHITE_LABEL_TEAM_ID,
      "accountId": accountNumber,
      creditAmount: Number(creditAbout)
    }
    Axios.post(`${API_BASE_URL}/credit/addNew`, data).then(res => {
      Swal.fire("Success!", `${res.data.success}`, "success")
    }, err=> {
      Swal.fire("Foul!", err.response.data.errorMessage, "error")
    })
  }

  return (
    <DashboardLayout>
      <Page className={classes.root} title="Customers">
        <Container maxWidth={false}>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Paper>
                <Box p={3} pt={2} pb={2}>
                  <Typography variant="h4" color="textPrimary">
                    Add credit
                  </Typography>
                </Box>
                <Divider />
                <Box p={3}>
                  <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="acc-number">
                          Account Number
                        </InputLabel>
                        <OutlinedInput
                          id="acc-number"
                          type="number"
                          labelWidth={125}
                          onChange={event =>
                            handleInputChange(event, 'account-number')
                          }
                        />
                      </FormControl>
                    </Box>

                    <Box mb={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="credit-about">
                          Credit amount
                        </InputLabel>
                        <OutlinedInput
                          id="credit-about"
                          type="number"
                          labelWidth={90}
                          onChange={event =>
                            handleInputChange(event, 'credit-about')
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <Button variant="contained" color="primary" type="submit"
                       onClick={
                        ()=>{
                          handleAddCredit()
                        }
                        }
                      >
                        Submit
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper>
                <Box p={3} pt={2} pb={2}>
                  <Typography variant="h4" color="textPrimary">
                    Lookup credit
                  </Typography>
                </Box>
                <Divider />
                <Box p={3}>
                  <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="acc-number">
                          Account Number
                        </InputLabel>
                        <OutlinedInput
                          id="acc-number"
                          type="number"
                          labelWidth={125}
                          onChange={event =>
                            handleInputChange(event, 'account-number')
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <Button variant="contained" color="primary" 
                      onClick={
                        ()=>{
                          handleLookup()
                        }
                      }
                      >
                        Lookup 
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </DashboardLayout>
  );
};

export default AddCreditView;
